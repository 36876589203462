.call-to-action {
  &__subtitle {
    font-size: 34px;
    font-weight: 700;
    color: $titleColor;
  }
  &__title {
    font-size: 18px;
    line-height: 30px;
    color:$cta;
    font-family: 'Gilda Display', serif;
  }
  &--btn {
    margin-top: 20px;
  }
}
