.social-share {
  margin-bottom: 20px;
  display: inline-block;
  .title {
    font-size: 12px;
    font-weight: 600;
    display: block;
    line-height: 1;
    margin-bottom: 10px;
    color: #8a8a8a;
    text-transform:uppercase;

  }
}

.share-icons {
  margin-bottom: 20px;
  padding-left:0px;
  li {
      display:inline-block;

      text-align: center;

      a {
        padding: 6px 2px;
        border-radius: 3px;
        color: rgba(0,0,0,.25);
        .fa {
          font-size:17px;
          width: 30px;
          height: 30px;

        }
      }
      a:hover {
        color: $white;

      }
    }
}
.bg-fb:hover {
  background-color:#afbdd4;
}
.bg-dr:hover {
  background-color:#ea4c89;
}
.bg-tw:hover {
  background-color:#0084b4;
}
.bg-go:hover {
  background-color:#d34836;
}
.bg-pi:hover {
  background-color:#cb2027;
}
.bg-mail:hover {
  background-color:$grey;
}
