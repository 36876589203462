/*** Variables ***/
@import '_vars';

/*** Misc settings ***/
@import 'utils/_mixins';
@import 'utils/_animations';

/*** General settings ***/
@import '_general';
@import '_site-header';
@import '_site-footer';

/*** Elements ***/
@import 'elements/_revolutionSlider';
@import 'elements/_iconbox';
@import 'elements/_titleBlock';
@import 'elements/_callToAction';
@import 'elements/_video';
@import 'elements/_studio';
@import 'elements/_testimonials';
@import 'elements/_portfolio';
@import 'elements/_latestPosts';
@import 'elements/_portfItem';
@import 'elements/_socialShare';
@import 'elements/_counter';
/*** Pages ***/
@import '_homepage';
@import '_blogpage';
