.txt-light {
  color: $white;
}
.txt-dark {
  color: $titleColor;
}
.txt-grey {
  color: $titlegrey;
}
.txt-light-transparent {
  color: rgba(255,255,255,0.45);
}
.txt-dark-transparent {
  color: rgba(10,10,10,0.4);
}
.txt-grey-transparent {
  color: rgba(255,255,255,0.69);
}
.txt-thin {
  font-weight: 400;
}
.txt-large {
  font-size: 48px;
  line-height: 56px;
  @include mobile {
    font-size: 38px;
    line-height: 46px;
  }
}
.title-block {
  text-align:left;
  margin-bottom:15px;
  &__title {
    margin-bottom: 20px;
    letter-spacing: -1px;
    font-size: 34px;
    line-height: 44px;
  }
  &__subtitle {
    margin-bottom: 20px;
    font-size: 13px;
    line-height: 26px;
    text-transform: uppercase;
  }
  &--center {
    text-align:center;
  }
  &--contact {
    text-align:center;
    .title-block__subtitle {
      text-align:center;
      font-weight: 700;
      font-size: 13px;
      line-height: 26px;
    }
    .title-block__title {
      font-size: 20px;
      font-weight: 700;
      font-family :$titleFont;
      line-height: 32px;
      letter-spacing: 0px;
      }

  }
  &--process {
    text-align:center;
    .title-block__title {
      font-family :$titleFont;
      font-size: 48px;
      line-height: 48px;
      font-weight: 700;
          margin-bottom: 30px;
      }
    .title-block__subtitle {
      text-align:center;
      font-weight: 700;
      font-size: 13px;
      line-height: 26px;
      text-transform: uppercase;
      letter-spacing: 2px;
       margin-bottom: 5px;
    }
  }
  &--process2 {
    text-align:center;
    .title-block__subtitle {
      text-align:center;
      text-transform: uppercase;
      letter-spacing: 2px;
       margin-bottom: 5px;
    }
    .title-block__title {
        margin-bottom: 30px;
      }
  }
}
.title-block__subtitle.client {
      font-size: 18px;
      line-height: 1.2;
      text-transform: none;
}
.portfolio-title {
  font-family: $titleFont;
  font-size: 64px;
  line-height: 74px;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  margin-bottom: 25px;
  padding-bottom: 25px;
  color:$titleColor;
  @include max_tablet {
      font-size: 44px;
      line-height: 54px;
  }
}
