.el-inline {
  display:inline;
}
.counter_element {
  font-family: "Montserrat",Helvetica,Arial,sans-serif;
  font-size: 44px;
  line-height: 15px;
  font-weight: 700;
  color: #0a0a0a;
  letter-spacing: -1px;
  margin-bottom: 30px;

}
.counter-wrapper{
  margin-bottom:30px;
}
