.ag-about-section {
  z-index:1;
  position:relative;
  @include mobile{
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (min-width:1699px) {
    width: 1500px;
    margin:0 auto;
  }

}
.ag-about {
  margin-top: -100px;
  position: relative;
  padding-bottom: 130px;
  @include max_tablet {
    padding-left: 60px;
    padding-right: 60px;
  }
  .quote-wrapper {
    width: 80%;
    margin: 0 auto;
  }


}
.home-page-first {
      margin-bottom: 40px;
}
.clients {
  margin-bottom:30px;
}
