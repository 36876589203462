.tp-caption.CreativeFrontPage-Btn,.CreativeFrontPage-Btn{
  color:#ffffff;
  font-size:14px;
  line-height:60px;
  font-weight:900;
  font-style:normal;
  font-family:Roboto;
  text-decoration:none;
  background-color:#00685c;
  border-color:#000000;
  border-style:solid;
  border-width:0px;
  border-radius:4px 4px 4px 4px;
  letter-spacing:2px}
  .tp-caption.CreativeFrontPage-Btn:hover,.CreativeFrontPage-Btn:hover{
    color:rgba(255,255,255,1.00);
    text-decoration:none;
    background-color:rgba(0,0,0,0.25);
    border-color:rgba(0,0,0,1.00);
    border-style:solid;
    border-width:0px;
    border-radius:4px 4px 4px 4px;
    cursor:pointer
  }
#rev_slider_1_1_wrapper .tp-loader.spinner2{
    background-color: #c4c4c4 !important;
}
.tp-flip-index{
     z-index:1000 !important;
}
#rev_slider_1_1 .uranus .tp-bullet{
  border-radius:50%;
  box-shadow:0 0 0 2px rgba(255,255,255,0);
  transition:box-shadow 0.3s ease;
  background:transparent;
  width:15px; height:15px
 }
   #rev_slider_1_1 .uranus .tp-bullet.selected,#rev_slider_1_1 .uranus .tp-bullet:hover{
     box-shadow:0 0 0 2px rgba(255,255,255,1);
     border:none;
     border-radius:50%;
     background:transparent
   }
   #rev_slider_1_1 .uranus .tp-bullet-inner{
  transition:background-color 0.3s ease,transform 0.3s ease;
  top:0;
 left:0;
  width:100%;
  height:100%;
 outline:none;
  border-radius:50%;
 background-color:rgb(255,255,255);
  background-color:rgba(255,255,255,0.3);
 text-indent:-999em;
cursor:pointer;
 position:absolute}
#rev_slider_1_1 .uranus .tp-bullet.selected .tp-bullet-inner,#rev_slider_1_1 .uranus .tp-bullet:hover .tp-bullet-inner{
  transform:scale(0.4); -webkit-transform:scale(0.4); background-color:rgb(255,255,255)
}
.rev_slider_wrapper.fullwidthbanner-container {
  @media (min-width:1699px) {
    width: 1700px;
    overflow: hidden!important;
  }
}
