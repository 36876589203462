/* colors */
$templateColor: #565656;
$titleColor: #0a0a0a;
$titlegrey: #aaaaaa;
$hoverColor: #d72a30;
$black: #000;
$iconblack: #323232;
$grey: #D9D9D9;
$label:  #969696;
$blackish:#242424;
$white: #fff;
$black-light:#404040;
$bl: #191919;
$social: #686868;
$pureblack: #141414;
$footerp: #808080;
$navlink: #afafaf;
$skew: #f8f8f8;
$cta: #939393;

/* fonts */
$defaultFont: 'Open Sans',Helvetica,Arial,sans-serif;
$titleFont: 'Montserrat', sans-serif;
$bodyFont: 'Roboto',Helvetica,Arial,sans-serif;
$quotefont: 'Gilda Display', serif;
$glyph: Glyphicons Halflings;
