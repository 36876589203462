.studio {
  .title-block__subtitle {
    margin-bottom: 0px;
  }
}
.team {
  .title-block__title{
    font-size: 20px;
    line-height: 30px;
    margin-bottom:0px;
  }
  .title-block__subtitle{
    font-size: 15px;
    line-height: 26px;
    font-weight: 400;
    font-style: normal;
    margin-top:0;
    text-transform: none;
    padding-bottom:10px;
  }
}
